import React, { useEffect, useState, useMemo } from 'react';
import Card from './Card';
import FantasyCardStats from './FantasyCardStats';
import useFantasyTeams from '../hooks/useFantasyTeams';
import useTeamImages from '../hooks/useTeamImages';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/FantasyTeamView.css';

const FantasyTeamView = ({ userID, event, season, onClose }) => {
    const { fantasyTeams, loading, error } = useFantasyTeams(userID, event._id);
    const [team, setTeam] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false); // State for overlay visibility
    const [overlayContent, setOverlayContent] = useState(null); // State for overlay content

    useEffect(() => {
        if (fantasyTeams) {
            // Map each card to its correct position
            const updatedTeam = (fantasyTeams[0].cards || []).map(card => ({
                ...card,
                position: card.position
            }));
            setTeam(updatedTeam);
        }
    }, [fantasyTeams]);

    const matches = useMemo(() => team.map(card => card.matches).flat(), [team]);

    const images = useTeamImages(matches);

    const getPointsStyle = (point) => {
        return {
            color: point < 0 ? 'var(--lightred)' : point > 0 ? 'var(--lightgreen)' : 'inherit',
        };
    };

    const cardStats = (fantasyCard) => {
        setOverlayContent(fantasyCard); // Set the card data to be displayed in the overlay
        setShowOverlay(true); // Show the overlay
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const getNextMatch = (teamID) => {
        if (!teamID || teamID._id === undefined) return null;
        const team = event.teamsInFantasy.find(t => t.team._id === teamID._id);
        if (!team) {
            return null;
        }
        if (team.placement !== null && team.placement !== undefined) {
            return { placement: team.placement };
        } else if (team.nextMatch && team.nextMatch.length > 0) {
            return team.nextMatch[team.nextMatch.length - 1];
        } else {
            return null;
        }
    };

    const renderNextMatchOrPlacement = (nextMatch) => {
        if (!nextMatch) return null;
        if (nextMatch.placement !== undefined) {
            return (
                <div className='event-points-box'>
                    <p className='card-points'>
                        {nextMatch.placement}
                    </p>
                </div>
            );
        }
        if (nextMatch.startTime + 1000 * 60 * 60 < Date.now()) {
            return (
                <div className='event-points-box-nextMatch'>
                    <p className='card-points'>
                        No match scheduled
                    </p>
                </div>
            );
        }
        return (
            <div className='event-points-box-nextMatch'>
                <p className='card-points'>
                    {nextMatch.opponent.name} &nbsp;-&nbsp; {formatMatchDate(nextMatch.startTime)}
                </p>
            </div>
        );
    };

    const formatMatchDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp);
        const options = {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const formattedDate = date.toLocaleDateString('da-DK', options).split('.');
        return `${formattedDate[0]}/${formattedDate[1]}:${formattedDate[2]}`;
    };

    return (
        <div className="overlay" onClick={handleOverlayClick}>
            <div className='card-menu-container' style={{ height: "100%" }}>
                <h1 className='title-events'>Fantasy Team</h1>
                <button className="overlay-close" onClick={onClose}>X</button>
                <div className="fantasyTeamView-content" onClick={(e) => e.stopPropagation()}>
                    <div className="event-fantasy-team-container overlay-content-center">
                        {loading && <div className='loader'></div>}
                        {error && <p className='error'>{error}</p>}
                        {!loading && !error && team.length === 0 && <p>No team data available.</p>}
                        {team.length > 0 && (
                            <div className="cards-container">
                                {Array.from({ length: 5 }).map((_, index) => {
                                    const card = team.find(c => c.position === index + 1);
                                    return (
                                        <div key={index} className='event-fantasy-player'>
                                            {card ? (
                                                <div onClick={() => cardStats(card)}>
                                                    {renderNextMatchOrPlacement(getNextMatch(card.currentTeam))}
                                                    <div className='card-hoverable'>
                                                        <Card card={card.card} />
                                                    </div>
                                                    <div className='event-points-box'>
                                                        <p className='card-points'>
                                                            <div><span style={getPointsStyle(card.points)}>{Math.round(card.points)}</span> Points</div>
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <img className='fantasy-player-image' src={'https://static.hltv.org/images/playerprofile/bodyshot/unknown.png'} alt='player' />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {fantasyTeams && (
                            <div className='event-teampoints-container'>
                                <div className='event-points-box total-points'>
                                    <p>
                                        Total points:
                                        <span style={getPointsStyle(fantasyTeams[0].points)}> {fantasyTeams[0].points.toFixed(2)}</span>
                                    </p>
                                </div>
                                <div className='event-points-box total-points'>
                                    <p>
                                        Boost points:
                                        <span style={getPointsStyle(fantasyTeams[0].points)}> {fantasyTeams[0].boostPoints.toFixed(2)}</span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Render the overlay */}
            {showOverlay && (
                <FantasyCardStats fantasyCard={overlayContent} onClose={() => setShowOverlay(false)} teamImages={images} />
            )}
        </div>
    );
};

export default FantasyTeamView;