import { Outlet } from "react-router-dom";
import Header from "./Header";
import React, { useEffect, useState } from 'react';
import '../css/Layout.css';

const Layout = () => {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isPortrait ?
        (
          <div className="overlay-change-orientation">
            <div className="overlay-change-orientation-content">
              <p>Please rotate your device to landscape mode and add web app to your home screen for the best experience.
              </p>
            </div>
          </div>
        )
        :
        (
          <>
            <Header />
            <main>
              <Outlet />
            </main>
          </>
        )
      }
    </>
  );
}

export default Layout;