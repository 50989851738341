import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchSuggestions from './SearchSuggestions';
import Dropdown from './Dropdown';
import { usePlayer } from '../hooks/usePlayer';
import { useTeam } from '../hooks/useTeam';
import { useBoost } from '../hooks/useBoost';

const Checkboxes = ({ selectedTiers, setSelectedTiers }) => {
    const handleCheckboxChange = (tier) => {
        setSelectedTiers(prev =>
            prev.includes(tier) ? prev.filter(t => t !== tier) : [...prev, tier]
        );
    };

    return (
        <div className="market-checkbox-group">
            <fieldset className="checkbox-group">
                <div className="checkbox-row">
                    <div className="checkbox">
                        <label className="checkbox-wrapper">
                            <input
                                type="checkbox"
                                className="checkbox-input"
                                checked={selectedTiers.includes(1)}
                                onChange={() => handleCheckboxChange(1)}
                            />
                            <span className="checkbox-tile">
                                <span className="checkbox-label">Bronze</span>
                            </span>
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className="checkbox-wrapper">
                            <input
                                type="checkbox"
                                className="checkbox-input"
                                checked={selectedTiers.includes(2)}
                                onChange={() => handleCheckboxChange(2)}
                            />
                            <span className="checkbox-tile">
                                <span className="checkbox-label">Silver</span>
                            </span>
                        </label>
                    </div>
                </div>
                <div className="checkbox-row">
                    <div className="checkbox">
                        <label className="checkbox-wrapper">
                            <input
                                type="checkbox"
                                className="checkbox-input"
                                checked={selectedTiers.includes(3)}
                                onChange={() => handleCheckboxChange(3)}
                            />
                            <span className="checkbox-tile">
                                <span className="checkbox-label">Gold</span>
                            </span>
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className="checkbox-wrapper">
                            <input
                                type="checkbox"
                                className="checkbox-input"
                                checked={selectedTiers.includes(4)}
                                onChange={() => handleCheckboxChange(4)}
                            />
                            <span className="checkbox-tile">
                                <span className="checkbox-label">Legendary</span>
                            </span>
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>
    );
};

const MarketFilter = ({
    cachedCards,
    setFilteredCards,
    originalCards,
    onSelectedBoostsChange,
    groupQuicksell,
    setIsGroupSelecting,
    allowGroupSelect = false,
    removedListings = [],
    resetPath,
    resetGroupSelectButton
}) => {
    const location = useLocation();
    const { playerNames } = usePlayer();
    const { teamNames } = useTeam();
    const { boostNames } = useBoost();
    const [searchPlayer, setSearchPlayer] = useState('');
    const [searchTeam, setSearchTeam] = useState('');
    const [selectedBoosts, setSelectedBoosts] = useState([null, null, null]);
    const [selectedBoostTiers, setSelectedBoostTiers] = useState([null, null, null]);
    const [selectedTiers, setSelectedTiers] = useState([]);
    const [isGroupSelecting, setIsGroupSelectingLocal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const player = queryParams.get('player');
        const team = queryParams.get('team');
        const rarity = queryParams.get('rarity');

        if (player) setSearchPlayer(player);
        if (team) setSearchTeam(team);
        if (rarity) setSelectedTiers([Number(rarity)]);
    }, [location.search]);

    useEffect(() => {
        if (resetGroupSelectButton) {
            resetGroupSelectButton.current = () => {
                setIsGroupSelectingLocal(false);
                setIsGroupSelecting(false);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetGroupSelectButton]);

    const handleBoostSelect = (item, index) => {
        const newSelectedBoosts = [...selectedBoosts];
        newSelectedBoosts[index] = item;
        setSelectedBoosts(newSelectedBoosts);
        onSelectedBoostsChange && onSelectedBoostsChange(newSelectedBoosts);
    };

    const handleBoostTierSelect = (item, index) => {
        const newSelectedBoostTiers = [...selectedBoostTiers];
        newSelectedBoostTiers[index] = item;
        setSelectedBoostTiers(newSelectedBoostTiers);
    };

    const handleBoostFilterReset = (index) => {
        const newSelectedBoosts = [...selectedBoosts];
        newSelectedBoosts[index] = null;
        setSelectedBoosts(newSelectedBoosts);

        const newSelectedBoostTiers = [...selectedBoostTiers];
        newSelectedBoostTiers[index] = null;
        setSelectedBoostTiers(newSelectedBoostTiers);
    };

    const handleSubmitFilters = () => {
        let filteredData = originalCards ? [...originalCards] : [...cachedCards];

        const getPlayerName = (item) => item.card ? item.card.player.name : item.player.name;
        const getTeamName = (item) => item.card ? item.card.player.team.name : item.player.team.name;
        const getTier = (item) => item.card ? item.card.tier : item.tier;
        const getBoosts = (item) => item.card ? item.card.boosts : item.boosts;

        if (searchPlayer) {
            filteredData = filteredData.filter(item =>
                getPlayerName(item).toLowerCase().includes(searchPlayer.toLowerCase())
            );
        }

        if (searchTeam) {
            filteredData = filteredData.filter(item =>
                getTeamName(item).toLowerCase().includes(searchTeam.toLowerCase())
            );
        }

        if (selectedTiers.length > 0) {
            filteredData = filteredData.filter(item =>
                selectedTiers.includes(getTier(item))
            );
        }

        for (let i = 0; i < 3; i++) {
            if (selectedBoosts[i]) {
                if (selectedBoostTiers[i]) {
                    filteredData = filteredData.filter(item =>
                        getBoosts(item).some(boost =>
                            boost.name === selectedBoosts[i].name &&
                            boost.tier === selectedBoostTiers[i].tier
                        )
                    );
                } else {
                    filteredData = filteredData.filter(item =>
                        getBoosts(item).some(boost =>
                            boost.name === selectedBoosts[i].name
                        )
                    );
                }
            }
        }

        filteredData = filteredData.filter(item => !removedListings.includes(item._id));

        setFilteredCards(filteredData);
    };

    const handleResetFilters = () => {
        if (!originalCards) {
            setFilteredCards(cachedCards.filter(card => !removedListings.includes(card._id)));
        } else {
            setFilteredCards(originalCards.filter(card => !removedListings.includes(card._id)));
        }
        setSearchPlayer('');
        setSearchTeam('');
        setSelectedBoosts([null, null, null]);
        setSelectedBoostTiers([null, null, null]);
        setSelectedTiers([]);
        resetPath && navigate(resetPath);
    };

    const handleGroupSelect = () => {
        const newIsGroupSelecting = !isGroupSelecting;
        setIsGroupSelectingLocal(newIsGroupSelecting);
        setIsGroupSelecting(newIsGroupSelecting); // Update the state in Inventory
        groupQuicksell();
        if (!newIsGroupSelecting) {
            resetGroupSelectButton(); // Reset button state if group selection is disabled
        }
    };

    const getResetButtonText = () => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('sellingCard')) {
            return 'Cancel Listing';
        } else if (queryParams.has('listing')) {
            return 'Cancel Compare';
        } else {
            return 'Reset Filters';
        }
    };

    return (
        <div className="market-filter col-2">
            <div className='market-filter-header'>
                <h1 className='title-inventory'>Filter</h1>
                {groupQuicksell && allowGroupSelect && (
                    <button className='default-button' onClick={handleGroupSelect}>
                        {isGroupSelecting ? 'Cancel' : 'Bulk Select'}
                    </button>
                )}
            </div>
            <div className='market-filter-inner'>
                <div className='market-search'>
                    <h4 className='title-inventory'>Player</h4>
                    <SearchSuggestions
                        query={playerNames}
                        searchTerm={searchPlayer}
                        setSearchTerm={setSearchPlayer}
                        suggestionsLength={10}
                        zIndex={3}
                    />
                </div>
                <div className='market-search'>
                    <h4 className='title-inventory'>Team</h4>
                    <SearchSuggestions
                        query={teamNames}
                        searchTerm={searchTeam}
                        setSearchTerm={setSearchTeam}
                        suggestionsLength={10}
                        zIndex={1}
                    />
                </div>
                <div className='market-search'>
                    <h4 className='title-inventory'>Rarity</h4>
                    <Checkboxes selectedTiers={selectedTiers} setSelectedTiers={setSelectedTiers} />
                </div>
                <div className='market-search'>
                    <h4 className='title-inventory'>Boosts</h4>
                    <div style={{ marginTop: "0.25vw" }}>
                        <h6 className='title-inventory'>Boost 1:</h6>
                        <div className='market-boost-search-container'>
                            <div className='market-boost-search'>
                                <Dropdown
                                    placeholder={"Boost"}
                                    query={boostNames}
                                    onSelect={(item) => handleBoostSelect(item, 0)}
                                    selectedItem={selectedBoosts[0]}
                                />
                            </div>
                            <div className='market-boost-tier-dropdown'>
                                <Dropdown
                                    placeholder={"Rarity"}
                                    query={[{ name: "Bronze", tier: 1 }, { name: "Silver", tier: 2 }, { name: "Gold", tier: 3 }]}
                                    onSelect={(item) => handleBoostTierSelect(item, 0)}
                                    selectedItem={selectedBoostTiers[0]}
                                />
                            </div>
                            <div className='market-boost-reset'>
                                <button className='default-button' onClick={() => handleBoostFilterReset(0)}>Reset</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "0.5vw" }}>
                        <h6 className='title-inventory'>Boost 2:</h6>
                        <div className='market-boost-search-container'>
                            <div className='market-boost-search'>
                                <Dropdown
                                    placeholder={"Boost"}
                                    query={boostNames}
                                    onSelect={(item) => handleBoostSelect(item, 1)}
                                    selectedItem={selectedBoosts[1]}
                                />
                            </div>
                            <div className='market-boost-tier-dropdown'>
                                <Dropdown
                                    placeholder={"Rarity"}
                                    query={[{ name: "Bronze", tier: 1 }, { name: "Silver", tier: 2 }, { name: "Gold", tier: 3 }]}
                                    onSelect={(item) => handleBoostTierSelect(item, 1)}
                                    selectedItem={selectedBoostTiers[1]}
                                />
                            </div>
                            <div className='market-boost-reset'>
                                <button className='default-button' onClick={() => handleBoostFilterReset(1)}>Reset</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "0.5vw" }}>
                        <h6 className='title-inventory'>Boost 3:</h6>
                        <div className='market-boost-search-container'>
                            <div className='market-boost-search'>
                                <Dropdown
                                    placeholder={"Boost"}
                                    query={boostNames}
                                    onSelect={(item) => handleBoostSelect(item, 2)}
                                    selectedItem={selectedBoosts[2]}
                                />
                            </div>
                            <div className='market-boost-tier-dropdown'>
                                <Dropdown
                                    placeholder={"Rarity"}
                                    query={[{ name: "Bronze", tier: 1 }, { name: "Silver", tier: 2 }, { name: "Gold", tier: 3 }]}
                                    onSelect={(item) => handleBoostTierSelect(item, 2)}
                                    selectedItem={selectedBoostTiers[2]}
                                />
                            </div>
                            <div className='market-boost-reset'>
                                <button className='default-button' onClick={() => handleBoostFilterReset(2)}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='market-filter-buttons'>
                    <button id="market-filter-submit-button" className='default-button market-filter-button' onClick={handleSubmitFilters}>Search</button>
                    <button className='default-button market-filter-button' onClick={handleResetFilters}>{getResetButtonText()}</button>
                </div>
            </div>
        </div>
    );
};

export default MarketFilter;