import React, { useState } from 'react';
import '../css/Welcome.css';
import { useSignUp } from '../hooks/useSignUp';
import { useLogIn } from '../hooks/useLogIn';

const Welcome = () => {
  const [isLogin, setIsLogin] = useState(true);

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { error, loading, signUp } = useSignUp();

  const { logInError, logInLoading, logIn } = useLogIn();


  const handleLogIn = async (e) => {
    e.preventDefault();
    await logIn(username, password);
  }

  const handleSignUp = async (e) => {
    e.preventDefault();
    await signUp(username, password, passwordConfirm);
  }


  return (
    <div className="welcome-container">
      <div className='content'>
        {isLogin ? (
          <div className="login-container ">
            <h1 className='title-welcome'>Ultimate</h1>
            <h1 className='title-welcome'>Counter-Strike</h1>
            <form onSubmit={handleLogIn}>
              <label className='login-label' htmlFor="usernameLogin">
                Username:
              </label>
              <input className='input-field'
                type="text"
                id="usernameLogin"
                name="usernameLogin"
                placeholder="Enter your Username"
                required
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />

              <label className='login-label' htmlFor="passwordLogin">
                Password:
              </label>
              <input className='input-field'
                type="password"
                id="passwordLogin"
                name="passwordLogin"
                placeholder="Enter your Password"
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <div className="wrap">
                <button className='default-button submit-button'
                  type="submit"
                  disabled={logInLoading}
                >
                  Log In
                </button>
              </div>
              {logInError &&
                <p>
                  <div className='error'>{logInError}</div>
                </p>
              }
            </form>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <p className='link switch' onClick={toggleForm} style={{ textDecoration: 'none' }}>
              Create an account
            </p>
          </div>





        ) : (





          <div className="login-container ">
            <h1 className='title-welcome'>Ultimate</h1>
            <h1 className='title-welcome'>Counter-Strike</h1>
            <form onSubmit={handleSignUp}>
              <label className='login-label' htmlFor="usernameSignUp">
                Username:
              </label>
              <input className='input-field'
                type="text"
                id="usernameSignUp"
                name="usernameSignUp"
                placeholder="Enter a Username"
                required
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />

              <label className='login-label' htmlFor="passwordSignUp">
                Password:
              </label>
              <input className='input-field'
                type="password"
                id="passwordSignUp"
                name="passwordSignUp"
                placeholder="Enter a Password"
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <label className='login-label' htmlFor="passwordConfirm">
                Confirm Password:
              </label>
              <input className='input-field'
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                placeholder="Confirm Password"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                value={passwordConfirm}
                required
              />

              <div className="wrap">
                <button className='default-button submit-button'
                  type="submit"
                  disabled={loading}
                >
                  Sign Up
                </button>
              </div>
              {error &&
                <p>
                  <div className='error'>{error}</div>
                </p>
              }
            </form>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <p className='link switch' onClick={toggleForm}>
              Log In
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Welcome;