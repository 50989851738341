import React, { useState, useEffect, useMemo, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Inventory.css';
import Card from './Card';
import CardMenu from './CardMenu';
import { useInventory } from '../hooks/fetchInventory';
import sortAscSVG from './SortAscendingLogo';
import sortDescSVG from './SortDescendingLogo';
import { useLocation } from 'react-router-dom';
import MarketFilter from './MarketFilter';
import CoinIcon from './CoinIcon';
import useQuickSell from '../hooks/useQuickSell';

const Inventory = ({ onCardClick, fullSize = false, allowedPlayers, team, locked = false, updateInventory }) => {
    const [isCardInventory, setIsCardInventory] = useState(true);
    const [isSkinInventory, setIsSkinInventory] = useState(false);
    const [sortBy, setSortBy] = useState();
    const [sortOrder, setSortOrder] = useState('desc');
    const [cachedCards, setCachedCards] = useState([]); // State to store cached cards
    const [originalCards, setOriginalCards] = useState([]); // State to store original unfiltered cards
    const [loading, setLoading] = useState(false);
    const { fetchInventoryCards } = useInventory();
    const [selectedCard, setSelectedCard] = useState(null);
    const [showCardMenu, setShowCardMenu] = useState(false);
    const cardCache = useRef(new Map()); // Ref to store the cached card data
    const location = useLocation();
    const [selectedBoosts, setSelectedBoosts] = useState([null, null, null]);
    const [isGroupSelecting, setIsGroupSelecting] = useState(false);
    const [groupSelectedCards, setGroupSelectedCards] = useState([]);
    const [totalQuickSellValue, setTotalQuickSellValue] = useState(0);
    const [isQuickSelling, setIsQuickSelling] = useState(false); // State to track quick sell loading
    const [reapplyFilters, setReapplyFilters] = useState(false);

    const { groupQuickSell, fetchCardFantasyTeamStatus } = useQuickSell();

    const handleSelectedBoostsChange = (boosts) => {
        setSelectedBoosts(boosts);
    };

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                if (isCardInventory) {
                    setIsSkinInventory(false);
                    const data = await fetchInventoryCards(null, sortBy, sortOrder);
                    let filteredData = data;
                    if (allowedPlayers) {
                        filteredData = filteredData.filter(card => allowedPlayers.some(player => player._id === card.player._id));
                    }
                    // Filter out cards that are already in the team
                    if (team) {
                        filteredData = filteredData.filter(card => !team.some(teamCard => teamCard.card === card._id));
                    }
                    setCachedCards(filteredData); // Cache the data
                    setOriginalCards(filteredData); // Store the original unfiltered data
                } else if (isSkinInventory) {
                    setIsCardInventory(false);
                    // Logic to fetch skins and update state accordingly
                    // Example:
                    // const data = await fetchInventorySkins(null, sortBy, sortOrder);
                    // setSkins(data);
                }
            } catch (error) {
                console.error("Error fetching inventory:", error);
            } finally {
                setLoading(false); // End loading
                setReapplyFilters(true); // Set reapply filters to true
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCardInventory, isSkinInventory, allowedPlayers, team, location.state?.refresh]);

    useEffect(() => {
        // Load and apply the saved sort option on page load
        const savedSortOption = localStorage.getItem('sortOption');
        if (savedSortOption) {
            setSortBy(savedSortOption);
            const button = document.getElementById('dropdown-button');
            if (button) {
                button.textContent = `Sort by: ${savedSortOption.charAt(0).toUpperCase() + savedSortOption.slice(1)}`;
            }
        }
    }, []);

    useEffect(() => {
        // Add the selected card's quicksell value to the total quicksell value
        if (groupSelectedCards.length > 0) {
            let totalValue = 0;
            groupSelectedCards.forEach(card => {
                totalValue += card.tier * card.stats[0].firepower;
            });
            setTotalQuickSellValue(totalValue);
        } else {
            setTotalQuickSellValue(0);
        }
    }, [groupSelectedCards]);

    useEffect(() => {
        if (!loading && reapplyFilters) {
            setReapplyFilters(false); // Reset reapply filters state
            // Call the handleSubmitFilters function from MarketFilter
            document.getElementById('market-filter-submit-button').click();
        }
    }, [loading, reapplyFilters]);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const setSortOption = debounce((option) => {
        // Save the selected option to localStorage
        localStorage.setItem('sortOption', option);

        // Update the dropdown button text
        const button = document.getElementById('dropdown-button');
        if (button) {
            button.textContent = `Sort by: ${option.charAt(0).toUpperCase() + option.slice(1)}`;
        }

        // Update the state
        setSortBy(option);
    }, 300);

    const sortedCards = useMemo(() => {
        if (!cachedCards) return [];
        const sorted = [...cachedCards];

        const compareBoosts = (a, b, boostIndex) => {
            if (selectedBoosts[boostIndex]) {
                const boostA = a.boosts.find(boost => boost.boost === selectedBoosts[boostIndex]._id);
                const boostB = b.boosts.find(boost => boost.boost === selectedBoosts[boostIndex]._id);
                if (boostA && boostB) {
                    return sortOrder === 'asc' ? boostA.value - boostB.value : boostB.value - boostA.value;
                } else if (boostA) {
                    return -1;
                } else if (boostB) {
                    return 1;
                }
            }
            return 0;
        };

        sorted.sort((a, b) => {
            let primaryComparison = 0;
            if (sortBy === 'name') {
                primaryComparison = sortOrder === 'asc' ? a.player.name.localeCompare(b.player.name) : b.player.name.localeCompare(a.player.name);
            } else if (sortBy === 'rarity') {
                primaryComparison = sortOrder === 'asc' ? a.tier - b.tier : b.tier - a.tier;
            } else if (sortBy === 'acquired') {
                primaryComparison = sortOrder === 'asc' ? new Date(a.acquired) - new Date(b.acquired) : new Date(b.acquired) - new Date(a.acquired);
            } else if (sortBy === 'nationality') {
                primaryComparison = sortOrder === 'asc' ? a.player.country.name.localeCompare(b.player.country.name) : b.player.country.name.localeCompare(a.player.country.name);
            } else if (sortBy === 'team') {
                primaryComparison = sortOrder === 'asc' ? a.player.team.name.localeCompare(b.player.team.name) : b.player.team.name.localeCompare(a.player.team.name);
            } else if (sortBy === 'rating') {
                primaryComparison = sortOrder === 'asc' ? a.player.stats[0].firepower - b.player.stats[0].firepower : b.player.stats[0].firepower - a.player.stats[0].firepower;
            } else if (sortBy === 'boost 1') {
                primaryComparison = compareBoosts(a, b, 0);
            } else if (sortBy === 'boost 2') {
                primaryComparison = compareBoosts(a, b, 1);
            } else if (sortBy === 'boost 3') {
                primaryComparison = compareBoosts(a, b, 2);
            } else {
                primaryComparison = sortOrder === 'asc' ? a.player.stats[0][sortBy] - b.player.stats[0][sortBy] : b.player.stats[0][sortBy] - a.player.stats[0][sortBy];
            }

            // Secondary comparison by name
            if (primaryComparison === 0) {
                return a.player.name.localeCompare(b.player.name);
            }

            return primaryComparison;
        });
        return sorted;
    }, [cachedCards, sortBy, sortOrder, selectedBoosts]);

    const openCardMenu = (card) => {
        setSelectedCard(card);
        setShowCardMenu(true);
    };

    const closeCardMenu = () => {
        setSelectedCard(null);
        setShowCardMenu(false);
    };

    const updateInventoryCache = (cardIDs) => {
        if (Array.isArray(cardIDs)) {
            // Handle array of card IDs
            setCachedCards(prevCards => prevCards.filter(card => !cardIDs.includes(card._id)));
            cardIDs.forEach(cardID => cardCache.current.delete(cardID)); // Remove each card from cache
        } else {
            // Handle single card ID
            setCachedCards(prevCards => prevCards.filter(card => card._id !== cardIDs));
            cardCache.current.delete(cardIDs); // Remove from cache
        }
    };

    const resetFilters = () => {
        setCachedCards(originalCards);
    };

    const groupQuicksell = () => {
        setIsGroupSelecting(!isGroupSelecting);
        setGroupSelectedCards([]);
    };

    const addToGroupSelectedCards = async (card) => {
        if (groupSelectedCards.includes(card)) {
            setGroupSelectedCards(groupSelectedCards.filter(selectedCard => selectedCard !== card));
            return;
        }

        // Check card's fantasy team status before adding to groupSelectedCards
        const status = await fetchCardFantasyTeamStatus(card._id);
        if (status.status === 'ACTIVE') {
            return;
        }

        setGroupSelectedCards([...groupSelectedCards, card]);
    };

    const handleCardClick = (card) => {
        if (onCardClick) {
            onCardClick(card);
            return;
        }
        else if (isGroupSelecting) {
            addToGroupSelectedCards(card);
            return;
        } else {
            openCardMenu(card);
        }
    };

    const resetGroupSelectButton = useRef(() => {
        setIsGroupSelecting(false);
    });

    const handleGroupQuicksell = async () => {
        setIsQuickSelling(true); // Start loading
        await groupQuickSell(groupSelectedCards.map(card => card._id));
        setGroupSelectedCards([]);
        updateInventoryCache(groupSelectedCards.map(card => card._id));
        setIsQuickSelling(false); // End loading
        setIsGroupSelecting(false); // Disable group selection
        resetGroupSelectButton.current(); // Reset button state in MarketFilter
    };

    return (
        <div className={`main-container ${fullSize ? '' : 'col-10 offset-1'}`} style={fullSize ? { height: 'auto', minHeight: '80%' } : {}}>
            <div className='title-container'>
                <div className=' inventory-box'>
                    <h1 className='title'>Inventory</h1>
                </div>
                <div className='inventory-box-filter'>
                    <div className="dropdown">
                        <button id="dropdown-button" className="dropdown-button">Select Sort Option</button>
                        <div id="dropdown-content" className="dropdown-content">
                            <p onClick={() => setSortOption('acquired')}>Acquired</p>
                            <p onClick={() => setSortOption('rarity')}>Rarity</p>
                            <p onClick={() => setSortOption('rating')}>Rating</p>
                            <p onClick={() => setSortOption('name')}>Name</p>
                            <p onClick={() => setSortOption('team')}>Team</p>
                            <p onClick={() => setSortOption('nationality')}>Nationality</p>
                            <p onClick={() => setSortOption('entrying')}>Entrying</p>
                            <p onClick={() => setSortOption('opening')}>Opening</p>
                            <p onClick={() => setSortOption('sniping')}>Sniping</p>
                            <p onClick={() => setSortOption('trading')}>Trading</p>
                            <p onClick={() => setSortOption('clutching')}>Clutching</p>
                            <p onClick={() => setSortOption('utility')}>Utility</p>
                            {selectedBoosts[0] && <p onClick={() => setSortOption('boost 1')}>Boost 1</p>}
                            {selectedBoosts[1] && <p onClick={() => setSortOption('boost 2')}>Boost 2</p>}
                            {selectedBoosts[2] && <p onClick={() => setSortOption('boost 3')}>Boost 3</p>}
                        </div>
                    </div>
                    <div className='sort-container'>
                        <div className='sort-svg' onClick={toggleSortOrder}
                            dangerouslySetInnerHTML={{ __html: sortOrder === 'desc' ? sortDescSVG : sortAscSVG }}
                        />
                    </div>
                </div>
            </div>

            {isCardInventory && (
                <div className={`${fullSize ? 'inventory-main-container' : 'market-container'}`}>
                    <MarketFilter
                        cachedCards={cachedCards}
                        setFilteredCards={setCachedCards}
                        resetFilters={resetFilters}
                        originalCards={originalCards}
                        onSelectedBoostsChange={handleSelectedBoostsChange}
                        groupQuicksell={groupQuicksell}
                        setIsGroupSelecting={setIsGroupSelecting}
                        allowGroupSelect={!fullSize}
                        resetGroupSelectButton={resetGroupSelectButton}
                    />
                    <div className={`${fullSize ? 'inventory-container-fullSize' : 'inventory-container'}`}>
                        {locked && (
                            <div className='inventory-locked'>
                                <p className='inventory-locked-text'>Inventory is locked</p>
                            </div>
                        )}
                        <div className='inventory-content'>
                            {loading && (
                                <div className='loader'></div>
                            )}
                            {sortedCards.length > 0 && (
                                sortedCards.map((card, index) => {
                                    // Check if the Card component is already cached
                                    const cacheKey = `${card._id}`;
                                    if (!cardCache.current.has(cacheKey)) {
                                        // Cache the Card component without the onClick handler
                                        cardCache.current.set(cacheKey, (
                                            <div className='inventory-card'>
                                                <Card key={index} card={card} />
                                            </div>
                                        ));
                                    }
                                    // Wrap the cached Card component with a container that has the latest onClick handler
                                    return (
                                        <div key={index} className={`col-3 cardDisplay-container ${groupSelectedCards.includes(card) ? 'highlighted-card' : ''}`} onClick={() => handleCardClick(card)}>
                                            {cardCache.current.get(cacheKey)}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                        {isGroupSelecting && (
                            <div className='inventory-sell-container'>
                                <div className='inventory-sellbar-container'>
                                    <CoinIcon width='2vh' height='2vh' />
                                    <div className='inventory-sellbar-text'>{totalQuickSellValue}</div>
                                </div>
                                <div className='inventory-button-container'>
                                    {isQuickSelling ? (
                                        <div className='loader-small'></div>
                                    ) : (
                                        <button className='default-button' onClick={() => handleGroupQuicksell()} >Quick Sell</button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showCardMenu && selectedCard && (
                <div className='overlay' onClick={closeCardMenu}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <CardMenu
                            card={selectedCard}
                            onClose={closeCardMenu}
                            updateInventoryCache={updateInventoryCache}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Inventory;