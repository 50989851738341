import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from 'react';

const LayoutGuest = () => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <>
      <Outlet />

      {isPortrait && (
        <div className="overlay-change-orientation">
          <div className="overlay-change-orientation-content">
            <p>Please rotate your device to landscape mode and add web app to your home screen for the best experience.
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default LayoutGuest;