import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Profile.css';
import { useLogOut } from '../hooks/useLogOut';
import { useUser } from '../hooks/useUser';
import { useUserStats } from '../hooks/useUserStats';
import { useFantasyTeams } from '../hooks/useFantasyTeams';
import { ThemeContext } from '../context/ThemeContext';

const Profile = () => {
  const { user } = useUser();
  const { userStats } = useUserStats();
  const { logOut } = useLogOut();
  const { fantasyTeams } = useFantasyTeams(user?._id);
  const { changeTheme } = useContext(ThemeContext);
  const [eventsParticipated, setEventsParticipated] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [totalBoostPoints, setTotalBoostPoints] = useState(0);
  const [averagePoints, setAveragePoints] = useState(0);
  const [winPercentage, setWinPercentage] = useState(0);

  useEffect(() => {
    if (fantasyTeams) {
      const finnishedFantasyTeams = fantasyTeams.filter(team => team.active === false);
      setEventsParticipated(finnishedFantasyTeams.length);
      let points = 0;
      let boostPoints = 0;

      for (const team of finnishedFantasyTeams) {
        points += team.points;
        boostPoints += team.boostPoints;
      }

      setTotalPoints(points);
      setTotalBoostPoints(boostPoints);
      setAveragePoints(finnishedFantasyTeams.length > 0 ? points / finnishedFantasyTeams.length : 0);
      setWinPercentage(finnishedFantasyTeams.length > 0 ? (userStats?.eventsWon / finnishedFantasyTeams.length) * 100 : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fantasyTeams]);

  useEffect(() => {
    // Load and apply the saved theme on page load
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      const button = document.getElementById('dropdown-button');
      if (button) {
        button.textContent = `Theme: ${savedTheme.charAt(0).toUpperCase() + savedTheme.slice(1)}`;
      }
    }
  }, []);

  const handleLogOut = async (e) => {
    logOut();
  }

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    return date.toLocaleDateString('da-DK', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const updateTheme = (theme) => {
    changeTheme(theme);
    const button = document.getElementById('dropdown-button');
    if (button) {
      button.textContent = `Theme: ${theme.charAt(0).toUpperCase() + theme.slice(1)}`;
    }
  }

  return (
    <div className='main-container col-10 offset-1 '>

      <div className='title-container'>
        <div className=' inventory-box'>
          <h1 className='title'>Profile</h1>
        </div>
        <div className='profile-options'>
          <div className='inventory-box-filter'>
            <div className="dropdown">
              <button id="dropdown-button" className="dropdown-button">Select Theme</button>
              <div id="dropdown-content" className="dropdown-content">
                <p onClick={() => updateTheme('Anubis')}>Anubis</p>
                <p onClick={() => updateTheme('Nuke')}>Nuke</p>
                <p onClick={() => updateTheme('Ancient')}>Ancient</p>
              </div>
            </div>
          </div>
          <button className='default-button' onClick={handleLogOut}>
            Log Out
          </button>
        </div>
      </div>

      <div className='profile-information-container'>
        <h3 className='title-inventory'>Information</h3>
        <div className='profile-information'>
          <h6>Username: {user?.username}</h6>
          <h6>Date Joined: {user && formatDate(user.dateJoined)}</h6>
        </div>
        {/* 
        <div>
          <button className='default-button' style={{ marginRight: "10px" }} onClick={handleLogOut}>
            Change Password
          </button>
          <button className='default-button' onClick={handleLogOut}>
            Change Email
          </button>
        </div>
        */}
      </div>

      <div className='profile-stats-main-container'>
        <h3 className='title-inventory'>Stats</h3>
        <div className='profile-stats-container'>
          <div className='profile-stats-table-container'>
            <table className='profile-stats-table'>
              <thead>
                <tr>
                  <th>Stat</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Packs Opened</td>
                  <td>{userStats?.packsOpened ?? 0}</td>
                </tr>
                <tr>
                  <td>Cards Opened</td>
                  <td>{userStats?.cardsOpened ?? 0}</td>
                </tr>
                <tr>
                  <td>Bronze Opened</td>
                  <td>{userStats?.bronzeOpened ?? 0} ({userStats?.bronzePercentage?.toFixed(2) ?? 0}%)</td>
                </tr>
                <tr>
                  <td>Silver Opened</td>
                  <td>{userStats?.silverOpened ?? 0} ({userStats?.silverPercentage?.toFixed(2) ?? 0}%)</td>
                </tr>
                <tr>
                  <td>Gold Opened</td>
                  <td>{userStats?.goldOpened ?? 0} ({userStats?.goldPercentage?.toFixed(2) ?? 0}%)</td>
                </tr>
                <tr>
                  <td>Legendaries Opened</td>
                  <td>{userStats?.legendariesOpened ?? 0} ({userStats?.legendaryPercentage?.toFixed(2) ?? 0}%)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='profile-stats-table-container'>
            <table className='profile-stats-table'>
              <thead>
                <tr>
                  <th>Stat</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Events Participated</td>
                  <td>{eventsParticipated ?? 0}</td>
                </tr>
                <tr>
                  <td>Average Placement</td>
                  <td>{userStats?.averagePlacement.toFixed(2) ?? 0}</td>
                </tr>
                <tr>
                  <td>Events Won</td>
                  <td>{userStats?.eventsWon ?? 0} ({winPercentage.toFixed(2)}%)</td>
                </tr>
                <tr>
                  <td>Total Points</td>
                  <td>{totalPoints?.toFixed(2) ?? 0}</td>
                </tr>
                <tr>
                  <td>Total Boost Points</td>
                  <td>{totalBoostPoints?.toFixed(2) ?? 0}</td>
                </tr>
                <tr>
                  <td>Average Points</td>
                  <td>{averagePoints?.toFixed(2) ?? 0}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;