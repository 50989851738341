import { useState } from 'react';
import { fetchWithAuth } from "./authHelpers";

export const useListing = () => {
    const [listings, setListings] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchListings = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch listings');
            }

            const data = await response.json();
            setListings(data.reverse());
        } catch (error) {
            console.error('Error fetching listings:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchListingById = async (listingId) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings/${listingId}`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch listing');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching listing:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const createListing = async (cardID, price) => {
        if (typeof cardID !== 'string') {
            console.error('Invalid cardID:', cardID);
            setError('Invalid cardID');
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings`, 'POST', { cardID, price });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create listing');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error creating listing:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const updateListing = async (listingId, price) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings/${listingId}`, 'PUT', { price });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update listing');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error updating listing:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const removeListing = async (listingId) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings/${listingId}`, 'DELETE');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove listing');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error removing listing:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    const deleteListing = async (listingId) => {
        setLoading(true);
        setError(null);

        try {
            console.log('Deleting listing:', listingId);
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings/delete/${listingId}`, 'DELETE');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete listing');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error deleting listing:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const buyListing = async (listingID) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/listings/buy/${listingID}`, 'POST');
    
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.message);
            }


        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    return { listings, loading, error, fetchListings, fetchListingById, createListing, updateListing, removeListing, deleteListing, buyListing };
};