import '../css/NewsSide.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useHltvNews } from '../../src/hooks/useHltvNews';
import Flag from 'react-world-flags'

const NewsSide = () => {
    const { news, loading, error } = useHltvNews();

    return (
        <div className="newsSide-container">
            <h1 className='title-news'>News</h1>
            <div className='newsBox'>
                {loading &&
                    <div className='loader' />
                }
                {news.length > 0 ?
                    <div>
                        {error && <div className='error'>Error fetching news</div>}
                        {news.map((item, index) => (
                            <div className='news' key={index} onClick={() => window.open(`https://www.hltv.org${item.link}`, '_blank')}>
                                <Flag className='news-flag' code={item.country.code} fallback={<Flag className='news-flag' code="EU" />} />
                                <p className='news-name' target="_blank" rel="noopener noreferrer">{item.title}</p>
                            </div>
                        ))}
                    </div>
                    :
                    !loading &&
                    <div className='event-frontpage-info' style={{ fontSize: "3rem" }}>NO NEWS AVIALABLE</div>
                }
            </div>
        </div>
    );
}

export default NewsSide;