import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Leaderboard.css';
import '../css/EventPage.css';
import useEvents from '../hooks/useEvents';
import useSeason from '../hooks/useSeason';
import { useNavigate } from 'react-router-dom';

const Events = ({ fullSize = false }) => {
    const [view, setView] = useState('current');
    const { events, error } = useEvents();
    const { seasons, fetchSeasons } = useSeason();
    const [season, setSeason] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 5;
    const [filteredEvents, setFilteredEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setSeason(seasons && seasons[0] && seasons[0]);
    }, [seasons]);

    useEffect(() => {
        fetchSeasons();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (view === 'current') {
            setFilteredEvents(events.filter(event => !event.isFinished));
        }
        if (view === 'archive') {
            // If a season is selected, filter by season and end date
            if (season) {
                setFilteredEvents(events.filter(event => event.isFinished && event.season === season._id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, events]);

    // Calculate the current entries to display
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = filteredEvents ? filteredEvents.slice(indexOfFirstEntry, indexOfLastEntry) : [];

    // Calculate total pages
    const totalPages = filteredEvents ? Math.ceil(filteredEvents.length / entriesPerPage) : 1;

    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp);
        return date.toLocaleDateString('da-DK', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        <div className={`main-container ${fullSize ? '' : 'col-10 offset-1'}`} style={fullSize ? { height: 'auto', minHeight: '800px' } : {}}>
            <div className='title-container'>
                <div className='col-10 inventory-box'>
                    <div className='col-auto'>
                        <h1 className='title'>Events</h1>
                    </div>
                    <div className="view-button-container">
                        <div className="col-auto">
                            <button className={`view-button ${view === 'current' ? 'active' : ''}`} onClick={() => setView('current')}>Current</button>
                        </div>
                        <div className="col-auto">
                            <button className={`view-button ${view === 'archive' ? 'active' : ''}`} onClick={() => setView('archive')}>Archive</button>
                        </div>
                    </div>
                </div>
                {view === 'archive' && (
                    <div className='col-2 inventory-box-filter'>
                        <div className="dropdown">
                            <button id="dropdown-button" className="dropdown-button">{season && season.name}</button>
                            <div id="dropdown-content" className="dropdown-content">
                                {seasons ? (
                                    seasons.map((season, index) => (
                                        <p key={index} onClick={() => setSeason(season)}>{season.name}</p>
                                    ))
                                ) : (
                                    <div className='loader' />
                                )}
                            </div>
                        </div>
                        <div className='sort-container'>
                            <div className='sort-svg' />
                        </div>
                    </div>
                )}
            </div>
            {filteredEvents ? (
                <div className='leaderbord-container'>
                    <div className='leaderboard-desc-container'>
                        <h4 className='col-1'> </h4>
                        <h4 className='col-2'>Event</h4>
                        <h4 className='col-2'>Start Date</h4>
                        <h4 className='col-2'>End Date</h4>
                        <h4 className='col-2'>Fantasy Teams</h4>
                        {view === 'archive' && <h4 className='col-2'>Winner</h4>}
                    </div>
                    <div className='leaderboard-content'>
                        {currentEntries.map((item, index) => (
                            <div
                                className={`leaderboard-entry-container big ${item.isLive === true ? 'isLive' : ''}`}
                                key={index}
                                onClick={() => navigate(`/event/${item.hltv_id}`)}
                            >
                                <div className='col-1' style={{ display: "flex", alignItems: "center", margin: "0 0.5vw" }}>
                                    <img className='image' src={item.image} alt={item.name} />
                                    <p className='live-text'>{item.isLive === true ? 'Live' : ''}</p>
                                </div>
                                <p className='col-2'>{item.name}</p>
                                <p className='col-2'>{formatDate(item.startDate)}</p>
                                <p className='col-2'>{formatDate(item.endDate)}</p>
                                <p className='col-2'>{item.fantasyTeams.length}</p>
                                {view === 'archive' && <p className='col-2'>{item.winner?.user.username}</p>}
                            </div>
                        ))}
                    </div>
                    <div className='pagination'>
                        <button className='default-button'
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1 || totalPages === 0}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className='default-button'
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages || totalPages === 0}
                        >
                            Next
                        </button>
                    </div>
                </div>
            ) : (
                error ? (
                    <p className='error'>Error fetching events</p>
                ) : (
                    <div className='loader' />
                )
            )}
        </div>
    );
};

export default Events;