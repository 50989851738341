import '../css/Card.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Flag from 'react-world-flags';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useCard } from '../hooks/fetchCard';
import UnkownPlayer from '../assets/unknownPlayer.png';

const Card = forwardRef(({ cardID, isOpening, size, market, scale }, ref) => {
    const { fetchCard } = useCard(cardID);
    const [card, setCard] = useState(null);
    const [tierClass, setTierClass] = useState('');
    const SHOWPLAYER = process.env.REACT_APP_SHOW_PLAYERS === 'true';

    useEffect(() => {
        fetchCard(cardID).then((data) => {
            setCard(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardID]);

    useEffect(() => {
        if (card) {
            const tierClass = getTierClass(card.tier);
            setTierClass(tierClass);
        }
    }, [card]);

    useImperativeHandle(ref, () => ({
        openCard
    }));

    const openCard = (e) => {
        if (e) {
            e.preventDefault();
            $(e.currentTarget).addClass('active');
        } else {
            $(`.card-${cardID}`).addClass('active');
        }
        // Ensure the card is scrolled into view
        const cardElement = document.querySelector(`.card-${cardID}`);
        if (cardElement) {
            cardElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    if (!card) {
        return null;
    }

    const getTierClass = (tier) => {
        switch (tier) {
            case 1:
                return 'bronzeCard';
            case 2:
                return 'silverCard';
            case 3:
                return 'goldCard';
            case 4:
                return 'legendaryCard';
            default:
                console.error('Invalid tier');
                return '';
        }
    };

    const getBoostTierColor = (boost) => {
        switch (boost.tier) {
            case 1:
                return 'var(--bronze1)';
            case 2:
                return 'var(--silver1)';
            case 3:
                return 'var(--gold1)';
            default:
                console.error('Invalid boost tier');
                return '';
        }
    };

    const formatStat = (stat) => {
        return stat < 10 ? `0${stat}` : stat;
    };

    return (
        <div className={`card-container ${size === 'small' ? 'small' : ''}`} style={{ transform: `scale(${scale})` }}>
            <div className={`card-${cardID} ${isOpening ? "" : "active static"} ${market ? "market" : ""} ${tierClass}`} id="card">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1">
                        <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
                            <path fill="#000" d="M 0.993 0.126 C 0.966 0.126 0.943 0.121 0.924 0.114 C 0.894 0.101 0.874 0.083 0.872 0.061 C 0.872 0.058 0.871 0.054 0.871 0.052 C 0.871 0.049 0.866 0.049 0.863 0.047 C 0.826 0.036 0.787 0.027 0.749 0.021 C 0.67 0.007 0.584 0 0.5 0 C 0.415 0 0.332 0.007 0.251 0.021 C 0.21 0.027 0.174 0.037 0.137 0.047 C 0.136 0.049 0.131 0.049 0.131 0.052 C 0.131 0.054 0.128 0.058 0.128 0.061 C 0.126 0.083 0.106 0.101 0.076 0.114 C 0.055 0.121 0.031 0.126 0.01 0.126 C 0.002 0.127 0.001 0.127 0.001 0.131 L 0.001 0.818 C 0.001 0.825 0.001 0.835 0.002 0.842 C 0.006 0.855 0.015 0.866 0.032 0.876 C 0.045 0.884 0.064 0.888 0.08 0.891 C 0.128 0.901 0.174 0.908 0.221 0.918 C 0.284 0.931 0.345 0.946 0.405 0.963 C 0.44 0.972 0.472 0.985 0.5 0.999 L 0.5 1 L 0.5 0.999 C 0.525 0.983 0.559 0.973 0.59 0.963 C 0.654 0.946 0.717 0.931 0.777 0.918 L 0.917 0.891 C 0.936 0.888 0.948 0.884 0.966 0.876 C 0.987 0.866 0.994 0.855 0.998 0.842 C 0.999 0.835 0.999 0.825 0.999 0.818 L 0.999 0.542 L 0.999 0.143 L 0.999 0.131 C 0.999 0.127 0.998 0.127 0.993 0.126 Z" />
                        </clipPath>
                </svg>
                <div id="card-inner">
                    <div id="card-top">
                        <div className="info">
                            <div className="value">{formatStat(card.player.stats[0].firepower)}</div>
                            <div className="position">{card.role}</div>
                            <div className="country"><div><Flag className='flag-card' code={card.player.country.code} /></div></div>
                            <div className="club"><img src={card.player.team.image ? card.player.team.image : card.team.image} style={{ transform: 'scale(0.8)' }} alt='team logo'></img></div>
                        </div>
                        <div className="image">
                            <img src={SHOWPLAYER === true ? card.player.image ? card.player.image : card.image : UnkownPlayer} style={{ width: '100%', transform: 'scale(1.2)' }} alt="player" />
                        </div>
                        <div className="backfont">CS</div>
                        <img src={`${process.env.PUBLIC_URL}/cardBackground.png`} style={{ width: '100%', height: '100%', transform: 'scale(1.6)' }} alt="" />
                    </div>
                    <div id="card-bottom">
                        <div className="name">
                            {card.player.name}
                        </div>
                        <div className="stats">
                            <div>
                                <ul>
                                    <li><span>{formatStat(card.player.stats[0].entrying)}</span><span className='statText'>entry</span></li>
                                    <li><span>{formatStat(card.player.stats[0].opening)}</span><span className='statText'>open</span></li>
                                    <li><span>{formatStat(card.player.stats[0].sniping)}</span><span className='statText'>awp</span></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><span>{formatStat(card.player.stats[0].trading)}</span><span className='statText'>trade</span></li>
                                    <li><span>{formatStat(card.player.stats[0].clutching)}</span><span className='statText'>clutch</span></li>
                                    <li><span>{formatStat(card.player.stats[0].utility)}</span><span className='statText'>utility</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-info">
                            {card.boosts.map((boost, index) => (
                                <div key={index} className="boost-item">
                                    <div className="boost-name-container">
                                        <div className="svg-container">
                                            <svg width="0.5vw" height="0.5vw" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="0.2vw" cy="0.2vw" r="0.2vw" fill={getBoostTierColor(boost)} />
                                            </svg>
                                        </div>
                                        <span className="boost-name">
                                            {boost.name}:
                                        </span>
                                    </div>
                                    <span className="boost-value">
                                        +{boost.value} pts
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Card;