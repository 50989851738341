import '../css/FrontPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import NewsSide from './NewsSide';
import EventsFrontPage from './EventsFrontpage';
import WinnerTeam from './WinnerTeam';


const FrontPage = () => {
    return (
        <div className='main-container col-10 offset-1'>
            <div className='frontpage-container'>
                <div style={{ width: '100%' }}>
                    <NewsSide />
                </div>
                <div className='col-8 frontpage-main-content'>
                    <EventsFrontPage />
                    <WinnerTeam />
                </div>
            </div>
        </div>
    )
}

export default FrontPage;