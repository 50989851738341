import React, { useState, useEffect, useRef } from 'react';
import { useOpenPack } from '../hooks/useOpenPack';
import { usePack } from '../../src/hooks/usePack';
import { useBank } from '../hooks/useBank';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/PackPage.css';
import '../css/Pack.css';
import '../css/Card.css';
import '../index.css';
import Card from './Card';
import CoinIcon from './CoinIcon';
import Unknown from '../assets/Unknown.png';

const PackPage = () => {
    const { packs, hasOpenedDailyPack, dailyPackID } = usePack();
    const [showDailyPack, setShowDailyPack] = useState(true);
    const { fetchCoins, subtractCoins } = useBank();
    const [selectedPack, setSelectedPack] = useState(null);
    const [openedCardsID, setOpenedCardsID] = useState([]);
    const cardRefs = useRef([]);
    const [lockOpening, setLockOpening] = useState(false);
    const [coins, setCoins] = useState(0);
    const [timeLeft, setTimeLeft] = useState('');
    const [isOpening, setIsOpening] = useState(true);
    const [allCardsOpened, setAllCardsOpened] = useState(false); // State to track if all cards are opened
    const timers = useRef([]);

    const openSelectPackPopUp = (pack) => {
        setSelectedPack(pack);
    };

    const closeSelectPackPopUp = () => {
        setSelectedPack(null);
    };

    const openPackOpeningPopUp = (cards) => {
        setIsOpening(true);
        setOpenedCardsID(cards);
        setAllCardsOpened(false); // Reset the state when opening a new pack
    };

    const closePackOpeningPopUp = () => {
        setOpenedCardsID([]);
        setLockOpening(false);
        clearTimers();
    };

    const quickOpen = () => {
        setLockOpening(false);
        setIsOpening(false);
        clearTimers();
        setAllCardsOpened(true); // Set the state to true when quick open is used
    };

    const clearTimers = () => {
        timers.current.forEach(timer => clearTimeout(timer));
        timers.current = [];
    };

    const { openPack, openDailyPack, data, loading } = useOpenPack();

    const handleOpenPack = async (tier) => {
        if (lockOpening === false) {
            if (await subtractCoins(tier.price) === false) {
                return;
            }
            try {
                await openPack(selectedPack._id, tier.type);
            } catch (error) {
                setLockOpening(false);
                console.error('Error opening pack:', error);
            }
            setLockOpening(true);
            fetchCoins().then((result) => {
                setCoins(result);
            })
        }
    };

    const handleOpenDailyPack = async (dailyPackID) => {
        if (lockOpening === false) {
            try {
                await openDailyPack(dailyPackID);
                setShowDailyPack(false);
            } catch (error) {
                setLockOpening(false);
                console.error('Error opening pack:', error);
            }
            setLockOpening(true);
        }
    };

    useEffect(() => {
        fetchCoins().then((result) => {
            setCoins(result);
        });
    });

    useEffect(() => {
        if (selectedPack && selectedPack.expireDate) {
            const targetDate = new Date(selectedPack.expireDate).getTime();

            const updateTimer = () => {
                const now = new Date().getTime();
                const distance = targetDate - now;

                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            };
            updateTimer();
            const interval = setInterval(updateTimer, 1000);

            return () => clearInterval(interval);
        } else { setTimeLeft('') }
        // eslint-disable-next-line
    }, [selectedPack]);

    useEffect(() => {
        if (data && data.cards && data.cards[0]) {
            openPackOpeningPopUp(data.cards);
            closeSelectPackPopUp();
        }
    }, [data]);

    useEffect(() => {
        if (openedCardsID.length > 0) {
            // Open the first card after half a second
            const firstTimer = setTimeout(() => {
                if (cardRefs.current[0]) {
                    cardRefs.current[0].openCard();
                }
                if (openedCardsID.length === 1) {
                    setLockOpening(false);
                    setAllCardsOpened(true); // Set the state to true when all cards are opened
                }
            }, 500); // 1 second delay for the first card
            timers.current.push(firstTimer);

            // Open the remaining cards with a delay
            openedCardsID.slice(1).forEach((cardID, index) => {
                const timer = setTimeout(() => {
                    if (cardRefs.current[index + 1]) { // Adjust index to account for the first card
                        cardRefs.current[index + 1].openCard();
                    }
                    // Unlock opening after the last card is opened
                    if (index === openedCardsID.length - 2) {
                        setLockOpening(false);
                        setAllCardsOpened(true); // Set the state to true when all cards are opened
                    }
                }, 1000 + (index + 1) * 3500);
                timers.current.push(timer);
            });
        }
    }, [openedCardsID]);

    useEffect(() => {
        const packContent = document.querySelector('.OpenPack-content');
        if (packContent) {
            const handleScroll = (event) => {
                if (event.deltaY !== 0) {
                    packContent.scrollLeft += event.deltaY;
                    event.preventDefault();
                }
            };
            packContent.addEventListener('wheel', handleScroll);
            return () => {
                packContent.removeEventListener('wheel', handleScroll);
            };
        }
    }, [openPack]);

    useEffect(() => {
        justifyContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedCardsID, allCardsOpened]);

    const justifyContent = () => {
        const packContainer = document.querySelector('.OpenPack-content');

        const checkCardContainer = () => {
            const cardContainer = document.getElementById('card');
            if (packContainer && cardContainer) {
                const totalCardsWidth = openedCardsID.length * cardContainer.clientWidth;
                const isOverflowing = totalCardsWidth > packContainer.clientWidth;

                if (isOverflowing) {
                    packContainer.style.justifyContent = 'flex-start';
                } else {
                    packContainer.style.justifyContent = 'center';
                }

                // Clear the interval once the cardContainer is found and processed
                clearInterval(intervalId);
            }
        };

        // Retry every second until the cardContainer is found
        const intervalId = setInterval(checkCardContainer, 1000);
    };

    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp);
        return date.toLocaleDateString('da-DK', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    };

    return (
        <div className='main-container col-10 offset-1'>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader'></div>
                </div>
            )}
            <div className='title-container'>
                <h1 className='title '>Packs</h1>
            </div>

            <div className='packPage-packs-container'>
                {!hasOpenedDailyPack && dailyPackID && showDailyPack && (
                    <div className='col-3 pack-container' onClick={() => handleOpenDailyPack(dailyPackID)}>
                        <h1 className='title-pack'>Daily Pack</h1>
                        <div className='card-container'>
                            <div className='bronze-pack active static' id="card">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"  >
                                    <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
                                        <path fill="#000" d="M 0.993 0.126 C 0.966 0.126 0.943 0.121 0.924 0.114 C 0.894 0.101 0.874 0.083 0.872 0.061 C 0.872 0.058 0.871 0.054 0.871 0.052 C 0.871 0.049 0.866 0.049 0.863 0.047 C 0.826 0.036 0.787 0.027 0.749 0.021 C 0.67 0.007 0.584 0 0.5 0 C 0.415 0 0.332 0.007 0.251 0.021 C 0.21 0.027 0.174 0.037 0.137 0.047 C 0.136 0.049 0.131 0.049 0.131 0.052 C 0.131 0.054 0.128 0.058 0.128 0.061 C 0.126 0.083 0.106 0.101 0.076 0.114 C 0.055 0.121 0.031 0.126 0.01 0.126 C 0.002 0.127 0.001 0.127 0.001 0.131 L 0.001 0.818 C 0.001 0.825 0.001 0.835 0.002 0.842 C 0.006 0.855 0.015 0.866 0.032 0.876 C 0.045 0.884 0.064 0.888 0.08 0.891 C 0.128 0.901 0.174 0.908 0.221 0.918 C 0.284 0.931 0.345 0.946 0.405 0.963 C 0.44 0.972 0.472 0.985 0.5 0.999 L 0.5 1 L 0.5 0.999 C 0.525 0.983 0.559 0.973 0.59 0.963 C 0.654 0.946 0.717 0.931 0.777 0.918 L 0.917 0.891 C 0.936 0.888 0.948 0.884 0.966 0.876 C 0.987 0.866 0.994 0.855 0.998 0.842 C 0.999 0.835 0.999 0.825 0.999 0.818 L 0.999 0.542 L 0.999 0.143 L 0.999 0.131 C 0.999 0.127 0.998 0.127 0.993 0.126 Z" />
                                    </clipPath>
                                    <image href={`${process.env.PUBLIC_URL}/cardBackground.png`} x="0" y="0" width="100%" height="100%" clipPath="url(#svgPath)" preserveAspectRatio="none"></image>
                                    <image href={Unknown} x="20%" y="15%" width="60%" height="60%" />
                                </svg>
                                <div className="pack-bottom">
                                    <div className="name">
                                        Daily Pack
                                    </div>
                                    <div className="stats">
                                        <div>
                                            <ul>
                                                {/* Doesn't update from db */}
                                                <li><span>Bronze</span><span className=''>90%</span></li>
                                                <li><span>Silver </span><span className=''>8%</span></li>
                                                <li><span>Gold</span><span className=''>1.5%</span></li>
                                                <li><span>Legendary</span><span className=''>0.5%</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className='price-pack'>Free</h1>
                    </div>
                )}
                {packs.length > 0 ? (
                    packs.map((pack, index) => (
                        <div key={index} className='col-3 pack-container' onClick={() => openSelectPackPopUp(pack)}>
                            <h1 className='title-pack'>{pack.name}</h1>
                            <div>
                                <img className='pack-image' src={pack.image ? pack.image : Unknown} alt={pack.name} />
                            </div>
                            <h1 className='price-pack'> Offer ends: {formatDate(pack.expireDate)} </h1>
                        </div>
                    ))
                ) : (
                    <div className='no-packs-container'>
                        <p>NO PACKS AVAILABLE AT THE MOMENT</p>
                    </div>
                )}
            </div>

            {selectedPack && (
                <div className='popup-overlay' onClick={closeSelectPackPopUp}>
                    <div className='SelectPack-container' onClick={(e) => e.stopPropagation()}>
                        <button className="overlay-close" onClick={closeSelectPackPopUp}>X</button>
                        <h1 className='title-pack'>{selectedPack.name}</h1>
                        <h1 className='price-pack'> Offer ends: {timeLeft} </h1>
                        <div className='SelectPack-content'>
                            {selectedPack.tiers.map((tier, index) => (
                                <div key={index} className='col-3 pack-tier-container' onClick={() => handleOpenPack(tier)}>
                                    <div className='card-container'>
                                        <div className={`${tier.type === 1 ? 'bronze-pack' : tier.type === 2 ? 'silver-pack' : 'gold-pack'} active static`} id="card">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"  >
                                                <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
                                                    <path fill="#000" d="M 0.993 0.126 C 0.966 0.126 0.943 0.121 0.924 0.114 C 0.894 0.101 0.874 0.083 0.872 0.061 C 0.872 0.058 0.871 0.054 0.871 0.052 C 0.871 0.049 0.866 0.049 0.863 0.047 C 0.826 0.036 0.787 0.027 0.749 0.021 C 0.67 0.007 0.584 0 0.5 0 C 0.415 0 0.332 0.007 0.251 0.021 C 0.21 0.027 0.174 0.037 0.137 0.047 C 0.136 0.049 0.131 0.049 0.131 0.052 C 0.131 0.054 0.128 0.058 0.128 0.061 C 0.126 0.083 0.106 0.101 0.076 0.114 C 0.055 0.121 0.031 0.126 0.01 0.126 C 0.002 0.127 0.001 0.127 0.001 0.131 L 0.001 0.818 C 0.001 0.825 0.001 0.835 0.002 0.842 C 0.006 0.855 0.015 0.866 0.032 0.876 C 0.045 0.884 0.064 0.888 0.08 0.891 C 0.128 0.901 0.174 0.908 0.221 0.918 C 0.284 0.931 0.345 0.946 0.405 0.963 C 0.44 0.972 0.472 0.985 0.5 0.999 L 0.5 1 L 0.5 0.999 C 0.525 0.983 0.559 0.973 0.59 0.963 C 0.654 0.946 0.717 0.931 0.777 0.918 L 0.917 0.891 C 0.936 0.888 0.948 0.884 0.966 0.876 C 0.987 0.866 0.994 0.855 0.998 0.842 C 0.999 0.835 0.999 0.825 0.999 0.818 L 0.999 0.542 L 0.999 0.143 L 0.999 0.131 C 0.999 0.127 0.998 0.127 0.993 0.126 Z" />
                                                </clipPath>
                                                <image src={`${process.env.PUBLIC_URL}/cardBackground.png`} x="0" y="0" width="100%" height="100%" clipPath="url(#svgPath)" preserveAspectRatio="none"></image>
                                                <image
                                                    href={selectedPack.image}
                                                    x="20%"
                                                    y="15%"
                                                    width="60%"
                                                    height="60%"
                                                />
                                            </svg>
                                            <div className="pack-bottom">
                                                <div className="name">
                                                    {tier.type === 1 ? 'Bronze' : tier.type === 2 ? 'Silver' : 'Gold'} Pack
                                                </div>
                                                <div className="stats">
                                                    <div>
                                                        <ul>
                                                            <li><span>Bronze</span><span className=''>{tier.odds.bronze}%</span></li>
                                                            <li><span>Silver </span><span className=''>{tier.odds.silver}%</span></li>
                                                            <li><span>Gold</span><span className=''>{tier.odds.gold}%</span></li>
                                                            <li><span>Legendary</span><span className=''>{tier.odds.legendary}%</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                    <h1 style={{ color: coins < tier.price ? 'var(--lightred)' : coins >= tier.price ? 'var(--lightgreen)' : 'inherit' }} className='price-pack'> <CoinIcon width="0.7vw" height="0.7vw" /> {tier.price && tier.price}</h1>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {openedCardsID && openedCardsID.length > 0 && (
                <div className='popup-overlay'>
                    <div className='OpenPack-container'>
                        <div className='OpenPack-content col-10'>
                            {openedCardsID.map((card, index) => (
                                <div key={index} className='cardDisplay-container' style={{ paddingBottom: 0, marginBottom: 0 }}>
                                    <Card ref={(el) => (cardRefs.current[index] = el)} cardID={card._id} size={"small"} isOpening={isOpening} />
                                </div>
                            ))}
                        </div>
                        <div className='OpenPack-footer'>
                            <button className='default-button close-button' onClick={closePackOpeningPopUp}>Close</button>
                            {!allCardsOpened && (
                                <button className='default-button close-button' onClick={quickOpen}>Quick Open</button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PackPage;