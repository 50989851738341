import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from '../hooks/useUser';
import CoinIcon from '../components/CoinIcon';
import InboxComponent from './InboxComponent';

function Header() {
    const [sticky, setSticky] = useState(false);
    const { user, loading, error } = useUser();
    const [coins, setCoins] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (user && user.bank) {
            setCoins(user.bank.coins);
        }
    }, [user]);

    useEffect(() => {
        const ws = new WebSocket(`wss://api.csultimatefantasy.com/ws`);

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'UPDATE_COINS' && data.userID === user?._id) {
                setCoins(data.coins);
            }
        };

        return () => {
            ws.close();
        };
    }, [user]);

    return (
        <header className={`${sticky ? "sticky" : ""}`}>
            <nav className="navbar col-10 offset-1">
                <div className="navbar-links">
                    <div className="close col-auto" style={{ paddingRight: "0" }}>
                        <a className={`${location.pathname === '/' && "header-current-tab"}`} href="/">Home</a>
                    </div>
                    <div className="col-auto close">
                        <a className={`${location.pathname === '/packs' && "header-current-tab"}`} href="/packs">Packs</a>
                    </div>
                    <div className="col-auto close">
                        <a className={`${location.pathname === '/inventory' && "header-current-tab"}`} href="/inventory">Inventory</a>
                    </div>
                    <div className="col-auto close">
                        <a className={`${location.pathname === '/events' && "header-current-tab"}`} href="/events">Events</a>
                    </div>
                    <div className="col-auto close">
                        <a className={`${location.pathname === '/market' && "header-current-tab"}`} href="/market">Market</a>
                    </div>
                    <div className="col-auto close">
                        <a className={`${location.pathname === '/leaderboard' && "header-current-tab"}`} href="/leaderboard">Leaderboard</a>
                    </div>
                    <div className="col-auto close">
                        <a className={`${location.pathname === '/howtoplay' && "header-current-tab"}`} href="/howtoplay">How to play</a>
                    </div>
                </div>
                <div className="navbar-user-info">
                    <div>
                        <InboxComponent userId={user?._id} />                    </div>
                    <div>
                        <p><CoinIcon width="0.9vw" height="0.9vw" /> {loading ? '' : error ? 'Error' : coins}</p>
                    </div>
                    <div>
                        <a className={`${location.pathname === '/profile' && "header-current-tab"}`} href="/profile">{loading ? '' : error ? 'Error' : user?.username || 'Profile'}</a>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;